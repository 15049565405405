const CONFIGURATION = {
    defaultTheme: 'mipasa-light',
    authorizedDomains: ['mipasa.com'],
    apps: {
        mipasa: {
            enabled: true,
            url: 'https://ch.mipasa.com',
        },
        unbounded: {
            enabled: false,
        },
        papillon: {
            enabled: false,
        },
        pasa: {
            enabled: false,
        },
    },
    oidc: {
        github: false,
        google: false,
        ethereum: false,
    },
    sentry: {
        enabled: false,
    },
};
export default CONFIGURATION;
