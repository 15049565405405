const CONFIGURATION = {
    defaultTheme: 'pasa-light',
    authorizedDomains: ['pasa.network'],
    apps: {
        mipasa: {
            enabled: false,
        },
        unbounded: {
            enabled: false,
        },
        papillon: {
            enabled: false,
        },
        pasa: {
            enabled: true,
            url: 'https://app.pasa.network',
        },
    },
    oidc: {
        github: true,
        google: true,
        ethereum: true,
    },
    sentry: {
        enabled: true,
        dsn: 'https://a7607287ba53453897b34276cb967443@o952724.ingest.sentry.io/6753216',
        tunnel: '/tunnel',
        environment: 'Crypto',
    },
    token: {
        tokenAddress: '0xa62a7344839Bd748DC4F0351D207B95464e65C7E',
        vestingAddress: '0x36acB157b2760284Bd0D32153F5F2Bc35BbcF68C',
        internalAddress: '0xe2abD1E57c8D6f0D21645a8a0Af6C4251a4395f5',
        name: 'UN',
        decimals: 18,
        unitRatio: 1,
    },
};
export default CONFIGURATION;
