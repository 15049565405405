const CONFIGURATION = {
    defaultTheme: 'mipasa-light',
    authorizedDomains: ['mipasa.com'],
    apps: {
        mipasa: {
            enabled: true,
            url: 'https://app.mipasa.com',
        },
        unbounded: {
            enabled: true,
            url: 'https://unbounded.mipasa.com',
        },
        papillon: {
            enabled: false,
        },
        pasa: {
            enabled: false,
        },
    },
    oidc: {
        github: true,
        google: true,
        ethereum: true,
    },
    sentry: {
        enabled: true,
        dsn: 'https://a7607287ba53453897b34276cb967443@o952724.ingest.sentry.io/6753216',
        tunnel: '/tunnel',
        environment: 'Production',
    },
};
export default CONFIGURATION;
